import React from 'react';
import { 
  Typography, 
  Box, 
  Container, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia,
  IconButton,
  useTheme,
  Paper,
  Grow,
  Zoom,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PaidIcon from '@mui/icons-material/Paid';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { motion } from 'framer-motion';

// Define the accent color and shadow color
const ACCENT_COLOR = '#50de8f';
const SHADOW_COLOR = '#152321';

// Styled components for enhanced visuals
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius * 2,
  position: 'relative',
  overflow: 'hidden',
  transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  boxShadow: `0 13px 27px -5px rgba(21, 35, 33, 0.25), 0 8px 16px -8px rgba(21, 35, 33, 0.3)`,
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: `0 30px 60px -12px rgba(21, 35, 33, 0.35), 0 18px 36px -18px rgba(21, 35, 33, 0.4)`,
  }
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  width: '80px',
  height: '80px',
  background: `linear-gradient(135deg, rgba(80, 222, 143, 0.15) 0%, rgba(80, 222, 143, 0.05) 100%)`,
  backdropFilter: 'blur(10px)',
  border: `1px solid rgba(80, 222, 143, 0.2)`,
  boxShadow: `0 8px 32px 0 rgba(21, 35, 33, 0.25)`,
}));

const CardNumber = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 16,
  right: 16,
  fontSize: '120px',
  fontWeight: 800,
  opacity: 0.08,
  zIndex: 0,
  color: ACCENT_COLOR,
}));

const BackgroundGradient = styled(Box)(({ theme, colorStart, colorEnd }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: `linear-gradient(135deg, ${colorStart || ACCENT_COLOR} 0%, ${colorEnd || SHADOW_COLOR} 100%)`,
  opacity: 0.08,
  zIndex: 0,
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(5),
  flex: 1,
}));

const HowItWorks = ({ mode, scrollToTop }) => {
  const theme = useTheme();
  const btnColor = mode === 'light' ? 'black' : 'white';
  
  // Colors for cards based on theme
  const getCardGradient = (index) => {
    const gradients = [
      { start: ACCENT_COLOR, end: SHADOW_COLOR }, // green to dark green
      { start: ACCENT_COLOR, end: SHADOW_COLOR }, // green to dark green
      { start: ACCENT_COLOR, end: SHADOW_COLOR }  // green to dark green
    ];
    return gradients[index % gradients.length];
  };

  // Function to get inverted colors
  const getInvertedColors = () => ({
    background: mode === 'light' ? '#232526' : '#fcfcfc',
    color: mode === 'light' ? '#fcfcfc' : '#232526',
    iconColor: ACCENT_COLOR
  });

  // Animation variants for staggered children animation
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5
      }
    }
  };

  const steps = [
    {
      title: "Write",
      description: "Deposit ETH or NFTs to write put or call options on any NFT collection. You receive an ERC721 NFT representing ownership of the option.",
      icon: <AccountBalanceIcon sx={{ fontSize: 48, color: ACCENT_COLOR }} />,
      color: getCardGradient(0)
    },
    {
      title: "Trade",
      description: "Buy and sell options through Ztrike's orderbook. Trade with 0% fees, you only pay gas!",
      icon: <ShowChartIcon sx={{ fontSize: 48, color: ACCENT_COLOR }} />,
      color: getCardGradient(1)
    },
    {
      title: "Exercise",
      description: "Exercise in-the-money options at any time before expiry. All options are fully collateralized, decentralized and free from counterparty risk.",
      icon: <PaidIcon sx={{ fontSize: 48, color: ACCENT_COLOR }} />,
      color: getCardGradient(2)
    }
  ];

  return (
    <Box
      component={motion.div}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }}
      variants={containerVariants}
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        padding: 4,
        overflow: 'hidden'
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 8, position: 'relative' }}>
          <Typography
            variant="overline"
            component={motion.div}
            variants={itemVariants}
            sx={{ 
              fontWeight: 'bold', 
              color: ACCENT_COLOR,
              letterSpacing: 4
            }}
          >
            DISCOVER
          </Typography>
          
          <Typography
            variant="h2"
            component={motion.div}
            variants={itemVariants}
            sx={{ 
              fontWeight: 'bold',
              position: 'relative',
              display: 'inline-block',
              mb: 2
            }}
          >
            How It Works
            <Box
              sx={{
                position: 'absolute',
                height: '4px',
                width: '60px',
                background: ACCENT_COLOR,
                bottom: -8,
                left: '50%',
                transform: 'translateX(-50%)'
              }}
            />
          </Typography>
          
          <Typography
            variant="body1"
            component={motion.div}
            variants={itemVariants}
            color="text.secondary"
            sx={{ maxWidth: '700px', mx: 'auto', mt: 3 }}
          >
            Ztrike makes NFT options trading simple, secure, and powerful.
            Our protocol enables you to manage risk, leverage your conviction, and earn yield on any NFT.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {steps.map((step, index) => (
            <Grid item xs={12} md={4} key={index} component={motion.div} variants={itemVariants}>
              <StyledCard>
                <BackgroundGradient colorStart={step.color.start} colorEnd={step.color.end} />
                <CardNumber>{index + 1}</CardNumber>
                
                <StyledCardContent>
                  <IconWrapper>
                    {step.icon}
                  </IconWrapper>
                  
                  <Typography 
                    variant="h4" 
                    gutterBottom 
                    sx={{ 
                      fontWeight: 'bold',
                      position: 'relative',
                      mb: 3
                    }}
                  >
                    {step.title}
                  </Typography>
                  
                  <Typography 
                    variant="body1" 
                    color="text.secondary"
                    sx={{ 
                      textAlign: 'center',
                      position: 'relative',
                      zIndex: 1
                    }}
                  >
                    {step.description}
                  </Typography>
                </StyledCardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >
          <IconButton
            component={motion.button}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={scrollToTop}
            sx={{
              color: btnColor,
              transform: 'rotate(180deg)',
              bgcolor: 'rgba(21, 35, 33, 0.15)',
              backdropFilter: 'blur(10px)',
              p: 2,
              '&:hover': {
                bgcolor: 'rgba(21, 35, 33, 0.25)',
              },
              boxShadow: `0 4px 20px rgba(21, 35, 33, 0.3)`
            }}
          >
            <KeyboardArrowUpIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default HowItWorks; 