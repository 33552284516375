import React from 'react';
import {Helmet} from "react-helmet";
import { createTheme, ThemeProvider } from '@mui/material';
//import { styled } from '@mui/material/styles';
import useStyles from '../styles';
import { Typography, Button, IconButton, Stack, Box, Card, CssBaseline, Container, AppBar, Toolbar, ButtonGroup} from '@mui/material'

//import { createTheme, ThemeProvider } from '@mui/material/styles';
import {AppBarModule} from '../AppBarModuleFP';
import {AppBarFooter} from '../AppBarFooter';
import SvgComponent from '../optionMetadata/OptionSVG';
import ZtrikeIcon from '../ZtrikeIcon.js' ;

import OSwhite from '../logos/OSwhite.svg' ;
import OSblack from '../logos/OSblack.svg' ;

import ESlogo from '../logos/etherscan-logo-light-circle.svg';
import ESlogoBlack from '../logos/etherscan-logo-dark.svg';

//import ZtrikePattern from '../logos/ZvgPatternAnimated.svg';
import ZtrikePatternDark from '../logos/ZvgPatternDark.svg';
import ZtrikePattern2 from '../logos/ZvgPattern2.svg';

import ZtrikePattern from '../ZtrikePattern.js';

import ZtrikeName from '../logos/ZvgZtrikeNameDark.svg';
import {Twitter} from '@mui/icons-material';
import Icon from '@material-ui/core/Icon';

import { useState } from 'react';

import { Link } from "react-router-dom";

// Import the new HowItWorks component
import HowItWorks from '../components/HowItWorks';

import * as ReactDOMServer from "react-dom/server";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import SavingsIcon from '@mui/icons-material/Savings';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaidIcon from '@mui/icons-material/Paid';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ImageIcon from '@mui/icons-material/Image';


import Background from '../components/flatSurface' // Add this import at the top

//import Background from '../components/3DSurface' // Add this import at the top

const App = () => {

    const [mode, setMode] = React.useState('light');
    const btnColor = mode == 'light' ? 'black' : 'white';

    function handleMode(){
        if (mode=="light"){
            setMode("dark");
        } else {
            setMode("light");
        }
    }
    const lightMode = {
      // Light mode
      primary: {
        light: '#757ce8',
        main: '#264a39',
        dark: '#607d8b',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#e64a19',
        contrastText: '#000',
      },
      background: {
        default: '#fcfcfc',
        paper: '#dbdbdb',
      },
    };
    const darkMode = {
      // Dark mode
      primary: {
        light: '#757ce8',
        main: '#264a39',
        dark: '#607d8b',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#e64a19',
        contrastText: '#000',
      },
      background: {
        default: '#414345',
        paper: '#232526',
      },
    };


    const theme = React.useMemo(
      () => createTheme({
        palette: {
          mode,
          ...(mode === 'light'
            ? lightMode
            : darkMode),
        },
        typography: {
          fontFamily: 'Inter',
        },
        shape: {
          borderRadius: 12,
        },
        custom: {
          gradient1: mode === 'dark'
            ? "radial-gradient(circle at top, #414345, #232526)"
            : "radial-gradient(circle at top, #fcfcfc, #dbdbdb)",
        },
      }), [mode]);

const [erc721OptionAddress, setOption] = useState('0x0792e69D2d3449895f047177E8Df2beEB05B09A6')
//const [scrollY, setScrollY] = useState(0);

/*React.useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);*/


const gradient1 = "radial-gradient(circle at top, #414345, #232526)";


// magic
const svgString = encodeURIComponent(
  ReactDOMServer.renderToStaticMarkup(
        <ZtrikePattern 
        lightColor = {mode=='light' ? '#dbdbdb' : '#232526'} //'#414345'
        darkColor = {mode=='light' ? '#ababab' : '#414345'} //'#fcfcfc'
        opacity = "0.2"
        />
    )
);



// const cards1 = [1, 2]; // Cards for option list

    const classes = useStyles(theme);

    const scrollToHowItWorks = () => {
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth'
        });
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // First, add this function inside the App component to get inverted colors
    const getInvertedColors = () => ({
        background: mode === 'light' ? darkMode.background.paper : lightMode.background.paper,
        color: mode === 'light' ? lightMode.background.paper : darkMode.background.paper,
        iconColor: mode === 'light' ? theme.palette.secondary.main : theme.palette.secondary.main
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ztrike | NFT Options </title>
                
            </Helmet>
            
            
            
            
            <div style={{ background: 'transparent'}} >
            
                <AppBarModule
                handleMode={handleMode}
                mode={mode}
                />
              
    <main >
    <div style={{ 
    position: 'relative', 
    minHeight: '100vh', 
    maxWidth: '100vw',  // Add this to prevent overflow
    overflow: 'hidden',  // Keep content within bounds
    margin: 0,          // Remove any margins
    padding: 0,         // Remove any padding
    background: theme.custom.gradient1  // Use your theme background
}}> 
    <div style={{ 
        backgroundImage: `url("data:image/svg+xml,${svgString}")`, 
        backgroundRepeat: 'repeat', 
        backgroundPosition: 'center', 
        backgroundSize: '120px',
        backgroundAttachment: 'fixed',
        opacity: '0.4',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        margin: 0,  // Ensure no margins
        padding: 0  // Ensure no padding
    }}>
    </div>

         <Box sx={{ height: '100vh', position: 'relative'  }}  display="flex"   flexDirection="column">
                    <div className={classes.container2}>
                        <div style={{ background: 'transparent'}}>         
                        </div>  
                            <br/><br/>                        
                            <Container maxWidth='md' sx={{marginLeft:{md:'23%', sm:'5%'}}}>
                                    <br/>
                                
                                        <Stack direction="row" spacing={2}>
                                            <SvgComponent strike='89.5'  contract='0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d' nftId='9999' writer='0xE4F5fb6Ef9494917d09e20E1B87413907Bdf7903' expiry='7140442' type='Put'/>
                                            <Box sx={{maxWidth: {xs:'37%',sm:'50%'} }}>
                                                
                                                    <Typography sx={{ typography: { xs: 'h5', sm: 'h4', md: 'h2' } }} >
                                                    The NFT Option Trading Protocol
                                                    </Typography>
                                                    <br/>
                                                    <Typography variant='body' >
                                                    Manage risk, leverage your conviction, and earn yield on any NFT
                                                    
                                                    </Typography>

                                            </Box>
                                        </Stack>                            
                                
                            
                            </Container>
                    </div>
                    
                                <Box   display="flex" justifyContent="center" alignItems="center">
                                    <Button component={Link} to="/app" variant='outlined' 
                                    style={{maxWidth: '50%',  minWidth: '50%'}}
                                    sx={{borderColor:btnColor, color:btnColor}}>
                                        Launch App </Button>
                                </Box>

                                <Box display="flex" justifyContent="center"  alignItems="center" sx={{paddingTop:2}}>
                                    <Stack direction="row" spacing={2}>
                                        <IconButton onClick={() => window.open('https://google.com')}><img src={mode === 'light' ? OSblack : OSwhite} width={30} /></IconButton>
                                        <IconButton onClick={() => window.open('https://goerli.etherscan.io/address/0x7002cC4e2b8b539E71c9BcEe8529c6AED17B8E96')}><img src={mode === 'light' ?  ESlogoBlack : ESlogo} width={30}/></IconButton>
                                        <IconButton  onClick={() => window.open('https://twitter.com/ztrike_io')}>
                                        <Twitter fontSize='large' fill={btnColor}/>  
                                        </IconButton>
                                        
                                    </Stack>
                                </Box>
                                
                    
                <div>     
            </div> 
                            <Box sx={{ 
                                position: 'absolute', 
                                bottom: 20, 
                                width: '100%', 
                                display: 'flex', 
                                justifyContent: 'center' 
                            }}>
                                <IconButton 
                                    onClick={scrollToHowItWorks}
                                    sx={{ 
                                        color: btnColor,
                                        animation: 'bounce 2s infinite'
                                    }}
                                >
                                    <KeyboardArrowDownIcon sx={{ fontSize: 40 }} />
                                </IconButton>
                            </Box> 
                            </Box>
        
                        {/* Replace the old "How it Works" section with our new component */}
                        <HowItWorks mode={mode} scrollToTop={scrollToTop} />
                        </div>   
                </main>
        </div>
       
                

            { true ? '':


           
            <AppBar position="static" sx={{ top: 'auto', bottom: 0, height:'10vh', marginTop:'-5vh'}}>
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    

                                <Stack direction="row" spacing={2}>
                                <ButtonGroup>
                                <Button variant="outlined" color='primary' component={Link} to="/app" > <Typography variant='overline' >App</Typography></Button>
                                <Button variant="outlined" color='primary' component={Link} to="/app" ><Typography variant='overline' >Docs</Typography></Button>
                                <Button variant="outlined" color='primary' component={Link} to="/app" ><Typography variant='overline' >Calc</Typography></Button>
                                </ButtonGroup>
                                
                                </Stack>
                                

                        <Stack direction="row" spacing={2}>
                                <IconButton onClick={() => window.open('https://opensea.io')}><img src={mode === 'light' ? OSblack : OSwhite} width={20} /></IconButton>
                                <IconButton onClick={() => window.open(`https://rinkeby.etherscan.io/address/${erc721OptionAddress}`)}><img src={mode === 'light' ?  ESlogoBlack : ESlogo} width={20}/></IconButton>
                                <IconButton  onClick={() => window.open('https://twitter.com/ztrike_io')}>
                                        <Twitter fontSize='medium'/>  
                                </IconButton>
                        </Stack>
                </Toolbar>     
            </AppBar>
             }
            
            
            
        </ThemeProvider>
    );
}

export default App;