import * as React from 'react';
import { Typography, Container, Grid, TextField, Button, Snackbar, Alert, Divider, Paper } from '@mui/material';
import { ethers } from 'ethers';
import BAYC from './artifacts/contracts/FakeBAYC.sol/BAYC.json';
import Option from './artifacts/contracts/ERC721Option.sol/ERC721Option.json';
import Marketplace from './artifacts/contracts/Marketplace.sol/Marketplace.json';
import { ZtrikeAddress, NFTAddress, MarketplaceAddress } from './ethContracts';
import { Link } from 'react-router-dom'; // Add this import
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Add this import


const INFURA_URL = process.env.REACT_APP_INFURA_URL;

// Helper function to format fee percentages
const formatFeePercentage = (value) => {
  if (!value) return '0.00';
  
  // Convert to number and divide by 100 to get percentage
  const numValue = Number(value);
  return (numValue / 100).toFixed(2);
};

export function ExtraMenu(props) {
  const { wallet, fakeBaycAddress, erc721OptionAddress, setOption } = props;

  // Add state for notifications
  const [notification, setNotification] = React.useState({
    open: false,
    message: '',
    severity: 'info'
  });

  // State for NFT transfer
  const [nftContractAddress, setNftContractAddress] = React.useState('');
  const [tokenId, setTokenId] = React.useState('');
  const [recipientAddress, setRecipientAddress] = React.useState('');
  
  // State for setFees function
  const [callWriteFeePct, setCallWriteFeePct] = React.useState('');
  const [putWriteFeePct, setPutWriteFeePct] = React.useState('');
  const [blockFeeFactor, setBlockFeeFactor] = React.useState('');
  const [minFeePct, setMinFeePct] = React.useState('');
  const [maxFeePct, setMaxFeePct] = React.useState('');
  const [currentFees, setCurrentFees] = React.useState(null);
  
  const handleBackToApp = () => {
    window.location.hash = ''; // This will remove the #admin hash
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const showNotification = (message, severity = 'info') => {
    setNotification({
      open: true,
      message,
      severity
    });
  };

  // New NFT transfer function with better error handling
  async function transferNFT() {
    if (!wallet) {
      showNotification('Please connect your wallet first', 'error');
      return;
    }

    if (!nftContractAddress) {
      showNotification('NFT Contract Address is required', 'error');
      return;
    }

    if (!tokenId) {
      showNotification('Token ID is required', 'error');
      return;
    }

    if (!recipientAddress) {
      showNotification('Recipient Address is required', 'error');
      return;
    }

    try {
      const [provider, signer, account] = await getProvider();
      
      const minABI = [
        "function safeTransferFrom(address from, address to, uint256 tokenId)"
      ];
      
      const nftContract = new ethers.Contract(nftContractAddress, minABI, signer);
      
      showNotification('Initiating transfer...', 'info');
      
      const transaction = await nftContract.safeTransferFrom(
        account,
        recipientAddress,
        tokenId
      );
      
      showNotification('Waiting for transaction confirmation...', 'info');
      await transaction.wait();
      
      showNotification(`NFT ${tokenId} successfully transferred to ${recipientAddress}`, 'success');
      
      // Clear form after successful transfer
      setNftContractAddress('');
      setTokenId('');
      setRecipientAddress('');
    } catch (error) {
      console.error("Transfer failed:", error);
      showNotification(
        `Transfer failed: ${error.message || 'Unknown error'}`, 
        'error'
      );
    }
  }


  async function getProvider() {
    if (wallet) {
      let account = wallet.accounts[0].address;
      const provider = new ethers.BrowserProvider(wallet.provider, 'any')
      const signer = await provider.getSigner()
      return [provider, signer, account];
    }
  }

  // Existing functions
  async function safeMint() {
    if (wallet) {
      const [provider, signer, account] = await getProvider();
      const contract = new ethers.Contract(NFTAddress, BAYC.abi, signer);
      const transation = await contract.safeMint(account);
      await transation.wait();
      console.log(`BAYC minted successfully sent to ${account}`);
    }
  }

  async function setContractAddressOPT() {
    if (wallet) {
      const [provider, signer, account] = await getProvider();
      const contract = new ethers.Contract(MarketplaceAddress, Marketplace.abi, signer);
      const contractP = new ethers.Contract(MarketplaceAddress, Marketplace.abi, provider);
      const currentContract = await contractP.OptionContractAddress();
      if (ZtrikeAddress !== currentContract) {
        const transation = await contract.setOptionContractAddress(ZtrikeAddress);
        await transation.wait();
        console.log(`Marketplace: Option contract successfully set to ${ZtrikeAddress}`);
      }
    }
  }

  async function setContractAddressMKT() {
    if (wallet) {
      const [provider, signer, account] = await getProvider();
      console.log("MP trying 0");
      const contract = new ethers.Contract(ZtrikeAddress, Option.abi, signer);
      const contractP = new ethers.Contract(ZtrikeAddress, Option.abi, provider);
      const currentContract = await contractP.MarketplaceAddress();

      console.log(currentContract, "??", MarketplaceAddress);
      if (MarketplaceAddress !== currentContract) {
        console.log(`MP trying 1 ${MarketplaceAddress}`);
        const transation = await contract.setMarketplaceAddress(MarketplaceAddress);
        console.log("MP trying 2");
        await transation.wait();
        console.log(`Option: Marketplace contract successfully set to ${MarketplaceAddress}`);
      } else {
        console.log("MP: they are the same");
      }
    }
  }

  // Function to fetch current fees
  async function fetchCurrentFees() {
    if (!wallet) {
      showNotification('Please connect your wallet first', 'error');
      return;
    }

    try {
      const [provider, signer, account] = await getProvider();
      const contract = new ethers.Contract(ZtrikeAddress, Option.abi, provider);
      
      // Correctly access public state variables
      const [callFee, putFee, blockFee, minFee, maxFee] = await Promise.all([
        contract.callWriteFeePct ? contract.callWriteFeePct() : contract.callWriteFeePct,
        contract.putWriteFeePct ? contract.putWriteFeePct() : contract.putWriteFeePct,
        contract.blockFeeFactor ? contract.blockFeeFactor() : contract.blockFeeFactor,
        contract.minFeePct ? contract.minFeePct() : contract.minFeePct,
        contract.maxFeePct ? contract.maxFeePct() : contract.maxFeePct
      ]);
      
      const feeValues = {
        callWriteFeePct: callFee ? callFee.toString() : '0',
        putWriteFeePct: putFee ? putFee.toString() : '0',
        blockFeeFactor: blockFee ? blockFee.toString() : '0',
        minFeePct: minFee ? minFee.toString() : '0',
        maxFeePct: maxFee ? maxFee.toString() : '0'
      };
      
      setCurrentFees(feeValues);
      
      // Pre-fill the form with current values
      setCallWriteFeePct(feeValues.callWriteFeePct);
      setPutWriteFeePct(feeValues.putWriteFeePct);
      setBlockFeeFactor(feeValues.blockFeeFactor);
      setMinFeePct(feeValues.minFeePct);
      setMaxFeePct(feeValues.maxFeePct);
      
      showNotification('Current fees loaded successfully', 'success');
    } catch (error) {
      console.error("Failed to fetch current fees:", error);
      showNotification(
        `Failed to fetch current fees: ${error.message || 'Unknown error'}`, 
        'error'
      );
    }
  }

  // Function to set fees
  async function updateFees() {
    if (!wallet) {
      showNotification('Please connect your wallet first', 'error');
      return;
    }

    // Validate inputs
    if (!callWriteFeePct || !putWriteFeePct || !blockFeeFactor || !minFeePct || !maxFeePct) {
      showNotification('All fee fields are required', 'error');
      return;
    }

    try {
      const [provider, signer, account] = await getProvider();
      const contract = new ethers.Contract(ZtrikeAddress, Option.abi, signer);
      
      // Ensure all values are valid numbers
      const callFee = parseInt(callWriteFeePct);
      const putFee = parseInt(putWriteFeePct);
      const blockFee = parseInt(blockFeeFactor);
      const minFee = parseInt(minFeePct);
      const maxFee = parseInt(maxFeePct);
      
      // Validate fee values
      if (isNaN(callFee) || isNaN(putFee) || isNaN(blockFee) || isNaN(minFee) || isNaN(maxFee)) {
        showNotification('All fees must be valid numbers', 'error');
        return;
      }
      
      if (minFee > maxFee) {
        showNotification('Minimum fee cannot be greater than maximum fee', 'error');
        return;
      }
      
      showNotification('Updating fees...', 'info');
      
      const transaction = await contract.setFees(
        callFee,
        putFee,
        blockFee,
        minFee,
        maxFee
      );
      
      showNotification('Waiting for transaction confirmation...', 'info');
      await transaction.wait();
      
      showNotification('Fees updated successfully', 'success');
      
      // Refresh current fees
      await fetchCurrentFees();
    } catch (error) {
      console.error("Failed to update fees:", error);
      showNotification(
        `Failed to update fees: ${error.message || 'Unknown error'}`, 
        'error'
      );
    }
  }

  // Load current fees when component mounts
  React.useEffect(() => {
    if (wallet) {
      fetchCurrentFees();
    }
  }, [wallet]);

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item>
          <Button
            onClick={handleBackToApp}
            startIcon={<ArrowBackIcon />}
            variant="outlined"
          >
            Back to App
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h4" gutterBottom>
        Admin Panel
      </Typography>
      <Snackbar 
        open={notification.open} 
        autoHideDuration={6000} 
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={3}>
        {/* Fee Management Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Fee Management
            </Typography>
            <Divider sx={{ mb: 2 }} />
            
            {currentFees && (
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom>
                    Current Fees:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2">
                    Call Write Fee: {currentFees.callWriteFeePct} ({formatFeePercentage(currentFees.callWriteFeePct)}%)
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2">
                    Put Write Fee: {currentFees.putWriteFeePct} ({formatFeePercentage(currentFees.putWriteFeePct)}%)
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body2">
                    Block Fee Factor: {currentFees.blockFeeFactor} ({formatFeePercentage(currentFees.blockFeeFactor)}%)
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    Min Fee: {currentFees.minFeePct} ({formatFeePercentage(currentFees.minFeePct)}%)
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">
                    Max Fee: {currentFees.maxFeePct} ({formatFeePercentage(currentFees.maxFeePct)}%)
                  </Typography>
                </Grid>
              </Grid>
            )}
            
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Call Write Fee (0.01% = 1)"
                  variant="outlined"
                  type="number"
                  value={callWriteFeePct}
                  onChange={(e) => setCallWriteFeePct(e.target.value)}
                  helperText="Fee percentage for writing call options"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Put Write Fee (0.01% = 1)"
                  variant="outlined"
                  type="number"
                  value={putWriteFeePct}
                  onChange={(e) => setPutWriteFeePct(e.target.value)}
                  helperText="Fee percentage for writing put options"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Block Fee Factor (0.01% = 1)"
                  variant="outlined"
                  type="number"
                  value={blockFeeFactor}
                  onChange={(e) => setBlockFeeFactor(e.target.value)}
                  helperText="Additional fee per 10000 blocks"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Min Fee (0.01% = 1)"
                  variant="outlined"
                  type="number"
                  value={minFeePct}
                  onChange={(e) => setMinFeePct(e.target.value)}
                  helperText="Minimum fee percentage"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Max Fee (0.01% = 1)"
                  variant="outlined"
                  type="number"
                  value={maxFeePct}
                  onChange={(e) => setMaxFeePct(e.target.value)}
                  helperText="Maximum fee percentage"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={fetchCurrentFees}
                  fullWidth
                >
                  Refresh Current Fees
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={updateFees}
                  fullWidth
                >
                  Update Fees
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* NFT Transfer Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Transfer NFT
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="NFT Contract Address"
                  variant="outlined"
                  value={nftContractAddress}
                  onChange={(e) => setNftContractAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Token ID"
                  variant="outlined"
                  value={tokenId}
                  onChange={(e) => setTokenId(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Recipient Address"
                  variant="outlined"
                  value={recipientAddress}
                  onChange={(e) => setRecipientAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={transferNFT}
                  fullWidth
                >
                  Transfer NFT
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Contract Management Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Contract Management
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={safeMint}
                  fullWidth
                >
                  Mint BAYC
                </Button>
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={setContractAddressMKT}
                  fullWidth
                >
                  Set Marketplace in Option Contract
                </Button>
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={setContractAddressOPT}
                  fullWidth
                >
                  Set Option Contract in Marketplace
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Option Contract Address"
                  variant="outlined"
                  value={ZtrikeAddress}
                  onChange={e => setOption(e.target.value)}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}